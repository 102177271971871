import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { LoggedInContext } from 'api/Context';

const PrivateRoute = ({Component}) => {
    const {isLoggedIn, Logout} = useContext(LoggedInContext);
    
    return isLoggedIn ? 
        <Component />
        : <Navigate to={"/login"} />
}

export default PrivateRoute;
