import React, { useState, useContext, useEffect } from 'react';
import { DialogTitle, DialogContent, Button, Stack } from '@mui/material';
import { Loader } from 'components/common/Loader';
import { PackagesContext, FirebaseContext, UserContext } from 'api/Context';

const SelectPackage = ({clickPackage}) => {
    const {userData, updateUserData} = useContext(UserContext);
    const {packages} = useContext(PackagesContext);
    const {firebaseStrings, firebaseConfig} = useContext(FirebaseContext);
    const [previousPackages, setPreviousPackages] = useState(null);
    const [promotionalImage, setPromotionalImage] = useState(null);

    useEffect(() => {
        if (firebaseConfig && userData && userData.sid) {
            if (!firebaseConfig.store.sale_active && firebaseConfig.store.intro_package_group.length && 
                firebaseConfig.store.intro_package_image.length && !userData.is_paid && !userData.is_corporate) {
                setPromotionalImage(firebaseConfig.store.intro_package_image);
            }
            if (firebaseConfig.store.sale_active && firebaseConfig.store.sale_image) {
                setPromotionalImage(firebaseConfig.store.sale_image);
            }
        }
    }, [firebaseConfig, userData]);

    return (
        <>
            <DialogTitle>Select Package</DialogTitle>
            <DialogContent id="SelectPackage" style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)"}}>
                {promotionalImage && promotionalImage !== "" &&
                <img src={promotionalImage} id='select-packages-promotional' />
                }
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} 
                    className={'sale-packages desktop'}>
                    {/* {packages && previousPackages && packages.map((p, i) => {
                        let previousCredits = "";
                        if (p.amount === previousPackages[i].amount) {
                            // previousCredits = previousPackages[i].description;
                            previousCredits = previousPackages[i].description;
                        }
                        return (
                            <div className="package has-previous-credits" key={i}>
                                <p className='package-amount'>${p.amount}</p>
                                <p className='previous-credits'>{previousCredits}</p>
                                <p>{p.description}</p>
                                <Button onClick={() => clickPackage(p, previousCredits)} variant="contained" className="green-button">Buy</Button>
                            </div>
                        )})
                    } */}
                    {packages && !previousPackages && packages.map((p, i) => {
                        let description = "";
                        description = p.metadata.credits + " Credits";

                        let product_image_url = "";
                        if (p.images && p.images.length) {
                            product_image_url = p.images[0];
                        }

                        let corp_class = "";
                        if (p.metadata && p.metadata.group === "corporate") {
                            corp_class = " corporate-package";
                        }
                        
                        return (
                            <div className={"package" + corp_class} key={i}>
                                <p className='package-amount'>
                                    ${p.amount}
                                </p>
                                <p className='product_description'>{description}</p>
                                <img className='product_image' src={product_image_url} alt={description} />
                                <Button onClick={() => clickPackage(p)} variant="contained" className="green-button">Buy</Button>
                            </div>
                        )})
                    }
                    {!packages &&
                        <Loader />
                    }
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} className='sale-packages mobile'>
                    {/* {packages && previousPackages && packages.map((p, i) => {
                        let previousCredits = "";
                        if (p.amount === previousPackages[i].amount) {
                            previousCredits = previousPackages[i].description;
                        }
                        return (
                            <div className="package has-previous-credits" key={i}>
                                <p>{p.description}</p>
                                <p className='previous-credits'>{previousCredits}</p>
                                <Button onClick={() => clickPackage(p, previousCredits)} variant="contained" className="green-button">
                                    Buy For ${p.amount}</Button>
                            </div>
                        )})
                    } */}
                    {packages && !previousPackages && packages.map((p, i) => {
                        let description = "";
                        description = p.metadata.credits + " Credits";

                        let product_image_url = "";
                        if (p.images && p.images.length) {
                            product_image_url = p.images[0];
                        }

                        return (
                            <div className="package" key={i}>
                                <p className='product_description'>{description}</p>
                                <img className='product_image' src={product_image_url} alt={description} />
                                <Button onClick={() => clickPackage(p)} variant="contained" className="green-button">
                                    Buy For ${p.amount}</Button>
                            </div>
                        )})
                    }
                    {!packages &&
                        <Loader />
                    }
                </Stack>
                <p style={{fontSize: "14px", margin: "15px 0 0"}}>Credits are used for SpoofCard calls and other features. 
                    The majority of calls only cost one credit per minute. 
                    International destinations outside of North America may cost more.</p>
            </DialogContent>
        </>
    );
};

export default SelectPackage;
