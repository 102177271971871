import { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TextsmsIcon from '@mui/icons-material/Textsms';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import SettingsIcon from '@mui/icons-material/Settings';
import SellIcon from '@mui/icons-material/Sell';
import GroupIcon from '@mui/icons-material/Group';
import { HideNavigation } from 'utils/HideNavigation';
import Purchase from 'components/views/Purchase';

const MobileNavigation = ({unreadCount, GetUserData, updateUserData}) => {
  const [userData, setUserData] = useState(null);
  const [openPurchase, setOpenPurchase] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
      GetUserData().then((response) => {
          return setUserData(response);
      })
  },[]);

  useEffect(() => {
    let unreadCountDiv = document.getElementById("unread-count-mobile");
    if (unreadCount !== null && unreadCount > 0) {
      unreadCountDiv.innerText = unreadCount;
      unreadCountDiv = unreadCountDiv.style.display = "inline-block";
    }
  }, [unreadCount]);

  const handlePurchaseDialogClose = () => {
      setOpenPurchase(false);
  };
  
  const handlePurchaseButtonClick = (e) => {
      e.preventDefault();
      setOpenPurchase(true);
  };

  if (HideNavigation.includes(currentLocation.pathname)) {
    return null;
  } else {
    return (
      <BottomNavigation value={currentLocation.pathname} className="MobileNavigation" showLabels>
        <BottomNavigationAction
          id='MobilePlaceCall'
          component={NavLink}
          to="/"
          value="/"
          label="Place Call"
          icon={<LocalPhoneIcon />}
        />
        <BottomNavigationAction
          id='MobileSendText'
          component={NavLink}
          to="/text"
          value="/text"
          label="Send Text"
          className="mobile-new-feature-badge"
          icon={<>
            <TextsmsIcon />
            {unreadCount && unreadCount > 0 &&
              <div className="value-badge" id="unread-count-mobile" style={{ display: "none"}}></div>
            }
          </>}
        />
        <BottomNavigationAction
          id='MobileCallHistory'
          component={NavLink}
          to="/call-history"
          value="/call-history"
          label="Call History"
          icon={<MenuBookIcon />}
        />
        <BottomNavigationAction
          id='AddCredits'
          component={NavLink}
          onClick={handlePurchaseButtonClick} 
          to="/buy"
          value="/buy"
          label="Add Credits"
          icon={<SellIcon />}
        />
        {userData && !userData.parent_account_sid && userData.is_corporate == 1 &&
          <BottomNavigationAction
            id='MobileManageUsers'
            component={NavLink}
            to="/manage-users"
            value="/manage-users"
            label="Users"
            icon={<GroupIcon />}
          />
        }                
        {userData && !userData.parent_account_sid && userData.is_corporate == 0 && userData.is_fraud == 0 && 
          userData.can_spoof == 1 &&
          <BottomNavigationAction
            id='MobileUpgradeAccount'
            component={NavLink}
            to="/upgrade-account"
            value="/upgrade-account"
            label="Upgrade"
            icon={<ArrowCircleUpIcon />}
          />
        }
        <BottomNavigationAction
          id='MobileSettings'
          component={NavLink}
          to="/settings"
          value="/settings"
          label="Settings"
          icon={<SettingsIcon />}
        />
        {openPurchase &&
            <Purchase open={openPurchase} closeDialog={handlePurchaseDialogClose} userData={userData} updateUserData={updateUserData} />
        }
      </BottomNavigation>
    );
  }
}

export default MobileNavigation;